import { FETCH_USER, FETCH_USER_SUCCESS , FETCH_USER_FAILED,
    FETCH_CUSTOMER, FETCH_CUSTOMER_SUCCESS, FETCH_CUSTOMER_FAILED,
    FETCH_PAYMENT_DETAIL, FETCH_PAYMENT_DETAIL_SUCCESS, FETCH_PAYMENT_DETAIL_FAILED,
    FETCH_CASHIER, FETCH_CASHIER_SUCCESS, FETCH_CASHIER_FAILED,
    FETCH_CS, FETCH_CS_FAILED, FETCH_CS_SUCCESS,
    FETCH_USER_LIST, FETCH_CUSTOMER_LIST } from "../actions/types"
const initialState = {
    pending: false,
    success: null,
    error: null,
    loading: true,
    data: {}
}

export default function(state = initialState, action){
    const{type, payload}=action;

    switch(type){
        case FETCH_USER:
        case FETCH_CUSTOMER:
        case FETCH_PAYMENT_DETAIL:
        case FETCH_USER_LIST:
        case FETCH_CASHIER:
        case FETCH_CS:
        case FETCH_CUSTOMER_LIST:
            return ({
                ...state,
                pending : true,
                success : null,
                error : null,
                data : {}
            })

        case FETCH_USER_SUCCESS:
        case FETCH_CUSTOMER_SUCCESS:
        case FETCH_PAYMENT_DETAIL_SUCCESS:
        case FETCH_CASHIER_SUCCESS:
        case FETCH_CS_SUCCESS:
            return ({
                ...state,
                pending : false,
                success : true,
                error : null,
                loading : false,
                data : payload
            })

        case FETCH_USER_FAILED:
        case FETCH_CUSTOMER_FAILED:
        case FETCH_PAYMENT_DETAIL_FAILED:
        case FETCH_CASHIER_FAILED:
        case FETCH_CS_FAILED:
            return({
                pending : false,
                success : false,
                error : payload,
                loading : false,
                data : {}
            })
        
        default:
            return state
    }
}