export const TVS_FORMAT_DATE = 'DD/MM/yyyy';
export const BE_FORMAT_DATE = 'yyyy-MM-DD';
//export const BaseURL = 'https://mcb92g0fzk.execute-api.ap-southeast-1.amazonaws.com/test'
//export const BaseURL = 'http://localhost:5000';
export const BaseURL = 'https://x3jg6bfgy3.execute-api.ap-southeast-1.amazonaws.com/test'; //dev jmpl
export const ACCURACY_MAPPING = {
  LOW: {
    color: '#CF0000',
    text: 'Low',
  },
  MED: {
    color: '#F35F04',
    text: 'Medium',
  },
  HIGH: {
    color: '#00A061',
    text: 'High',
  },
};

export const CURRENCY_TYPE = 'NPR'

export const formatCurrency = (value) => {
  if (!value) return '';
  const num = value?.toString().replace(/,/gi, '');
  const splitDecima = num.toString().split('.');
  const num2 = splitDecima[0].split(/(?=(?:\d{3})+$)/).join(',');
  return `${num2}${splitDecima[1] ? `.${splitDecima[1]}` : '.00'}`;
};

export const PAYMENT_NOTES = ['Full Payment', 'Partial Payment', 'Promise to Pay'];

export const PTP_LOCATION = ['Branch', 'Field'];

export const OWNER_OF_PHONE_NUMBER = [
  'Customer',
  'Parent / Parent-in-law',
  'Sibling',
  'Child / Grandchild',
  'Grandparent',
  'Spouse / Partner',
  'Friend',
  'Other',
];

export const CASHIER_ROLE = 'A';
export const MANAGER_ROLE = 'M';
export const COOPERATOR_ROLE = 'C';
export const COLLECTION_SPECIALIST = 'F';

export const PARTIAL_PAYMENT = 'Partial Payment';
export const PROMISE_TO_PAY = 'Promise to Pay';
export const CALL_BACK_LATER = 'Call Back Later';
export const FULL_PAYMENT = 'Full Payment';
export const ALREADY_MADE_PAYMENT = 'Already Made Payment';
export const NOT_CONTACTABLE = 'Not Contactable';
export const LEFT_MESSAGE = 'Left Message';

export const isStyleObject = (obj) => typeof obj === 'object';
export const IDLE_TIMEOUT = 1800;

export const ROLES = [
  {
    id: COOPERATOR_ROLE,
    value: COOPERATOR_ROLE,
    label: "Corporate Manager"
  },
  {
    id: MANAGER_ROLE,
    value: MANAGER_ROLE,
    label: "Manager"
  },
  {
    id: CASHIER_ROLE,
    value: CASHIER_ROLE,
    label: "Cashier"
  },
  {
    id: COLLECTION_SPECIALIST,
    value: COLLECTION_SPECIALIST,
    label: "Collection Specialist"
  }
]
export const USER_TYPE = [
  {
    id: 'new',
    value: 'new',
    label: "New User"
  },
  {
    id: 'existing',
    value: 'existing',
    label: "Existing User"
  }
]
export const ADDRESS_TYPE = [
  {
    id: 'Home',
    value: 'Home',
    label: 'Home',
  },
  {
    id: 'Office',
    value: 'Office',
    label: 'Office',
  },
  {
    id: 'Relative',
    value: 'Relative',
    label: 'Relative',
  },
  {
    id: 'Comaker',
    value: 'Comaker',
    label: 'Comaker',
  },
  {
    id: 'Other',
    value: 'Other',
    label: 'Other',
  },
];

export const NEPAL_TZ = 'Asia/Kathmandu';
