import { FETCH_STARTUP, FETCH_STARTUP_ADDRESS_SUCCESS, FETCH_STARTUP_ADDRESS_FAILED ,
     FETCH_STARTUP_BRANCH_SUCCESS, FETCH_STARTUP_BRANCH_FAILED, FETCH_CS_FOR_MAPPING_SUCCESS, FETCH_CS_FOR_MAPPING_FAILED,
    FETCH_MANAGER_LIST_SUCCESS, FETCH_MANAGER_LIST_FAILED} from "../actions/types"
const initialState = {
    pending: false,
    success: null,
    error: null,
    loading: true,
    data: {
        address : null,
        branch : null,
        csList : null,
        managerList: null
    }
}

export default function(state = initialState, action){
    const{type, payload}=action;

    switch(type){
        case FETCH_STARTUP:
            return ({
                ...state,
                pending : true,
                success : null,
                error : null,
            })

        case FETCH_STARTUP_ADDRESS_SUCCESS:
            return ({
                ...state,
                pending : false,
                success : true,
                error : null,
                data : {
                    ...state.data,
                   address : payload}
            })
        
        case FETCH_STARTUP_BRANCH_SUCCESS:
            return ({
                ...state,
                pending : false,
                success : true,
                error : null,
                data : {
                    ...state.data,
                    branch : payload}
            })

        case FETCH_CS_FOR_MAPPING_SUCCESS:
            return({
                ...state,
                pending : false,
                success : true,
                error : null,
                data : {
                    ...state.data,
                    csList : payload}
            })
        
        case FETCH_MANAGER_LIST_SUCCESS:
            return({
                ...state,
                pending : false,
                success : true,
                error : payload,
                data : {
                    ...state.data,
                    managerList : payload
                }
            })

        case FETCH_STARTUP_ADDRESS_FAILED:
            return({
                pending : false,
                success : false,
                error : payload,
                data : {
                    ...state.data,
                    address : null
                }
            })
            case FETCH_STARTUP_BRANCH_FAILED:
                return({
                    pending : false,
                    success : false,
                    error : payload,
                    data : {
                        ...state.data,
                        branch : null
                    }
                })
            
            case FETCH_CS_FOR_MAPPING_FAILED:
                return({
                    ...state,
                    pending : false,
                    success : false,
                    error : payload,
                    data : {
                        ...state.data,
                        csList : null}
                })
            
            case FETCH_MANAGER_LIST_FAILED:
                return({
                    ...state,
                    pending : false,
                    success : false,
                    error : payload,
                    data : {
                        ...state.data,
                        managerList : null
                    }
                })
        
        default:
            return state
    }
}