/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useField, useFormikContext } from 'formik';
import { Label, FormGroup } from 'reactstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types'


const TvsSelect = ({ options, id, label, placeholder, disabled, loading, handleChange }) => {
  const [metadata] = useField(id);
  const { errors, touched, values, setValues } = useFormikContext();

  const [optionList, setOptionList] = useState([]);

  const inputTouched = touched[id] || false;
  const inputErrors = errors[id] || false;

  useEffect(() => {
    setOptionList(options.map((item) => (typeof item === 'string' ? { value: item, label: item } : item)));
  }, [options]);

  const onChange = (value) => {
    setValues({
      ...values,
      [id]: value.value,
    });
  };

  const customStyles = {
    menu: () => ({
      minWidth: '300px',
      position: 'absolute',
      zIndex: 100,
      background: '#fff',
    }),
    valueContainer: () => ({
      display: 'flex',
      paddingLeft: '10px',
      paddingRight: '10px',
      width: '80%',
      overflow: 'hidden',
      position: 'relative',
    }),
  };

  return (
    <FormGroup className="react-select">
      {label && <Label>{label}</Label>}
      <SelectStyle
        id={id}
        options={optionList}
        value={metadata.value ? optionList.filter((opt) => opt?.value === metadata.value) : ''}
        onChange={handleChange || onChange}
        name={id}
        styles={customStyles}
        placeholder={placeholder}
        isDisabled={disabled}
        isLoading={loading}
      />

      {inputTouched && inputErrors && (
        <div className="text-danger form-text text-small">{inputTouched && inputErrors}</div>
      )}
    </FormGroup>
  );
};

const SelectStyle = styled(Select)`
  > div {
    min-height: 50px;
    border: 1px solid #d8d8d8;
    border-radius: 2px;
    div[class*='indicatorContainer'] {
      padding-left: 0;
    }
    span[class*='indicatorSeparator'] {
      display: none;
    }
    div[class*='-menu'] {
      width: 400px;
      z-index: 100;
    }
    &:hover {
      border: 1px solid #80bdff;
      box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    }
  }
`;

TvsSelect.propTypes = {
  options: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.string,
  handleChange: PropTypes.func,
}
export default TvsSelect;
