import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import{Route, Redirect} from 'react-router-dom';

const PrivateRoute = ({component: Component, auth, ...rest}) => (
    <Route {...rest} 
    render={props => !auth.isAuthenticated && !auth.loading ? (<Redirect to = '/login' />) : 
(<Component {...props} /> )} />
)

PrivateRoute.propTypes ={
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state =>({
    auth: state.auth   //state.x  x=>whatever you want from reducer
})

export default connect(mapStateToProps)(PrivateRoute);
