import { FULL_PAYMENT, PARTIAL_PAYMENT, PROMISE_TO_PAY } from '../constant';
import validator from 'validator';

export const PHONE_VALIDATE = [
  {
    value: '+65',
    validate: {
      length: 8,
    },
  },
  {
    value: '+63',
    validate: {
      length: 10,
    },
  },
  {
    value: '+977',
    validate: {
      length: 10,
    },
  },
  {
    value: '+91',
    validate: {
      length: 10,
    },
  },
];

const checkAmountCurrency = (cash) => {
  const splitDec = cash.toString().replace(/,/gi, '');
  const regexp = /^\d+(\.\d{1,2})?$/;
  if (!regexp.test(splitDec)) {
    return 'Please check the amount entered.';
  }
  return false;
};

export const updateCustomerValidate = (values) =>{
  const errors = {};
  if(values?.expected_amt && !values.month_due_date){
      errors.month_due_date = "Please enter the expected date od due payment"
  }

  if (values.phone_number) {
    if (isNaN(values.phone_number)) errors.phone_number = 'Please enter valid phone number';
    if (!values.country_code) errors.country_code = 'Please select country code';

    const mappingValidation = PHONE_VALIDATE.filter((item) => item.value === values.country_code)[0];
    if (mappingValidation && values.phone_number.length !== mappingValidation?.validate?.length) {
      errors.phone_number = 'Please enter valid phone number';
    }
    return errors;
}
}

export const createValidate = (values) =>{
  const errors = {};

  if(!values.first_name){
    errors.first_name = "Please enter your name";
  }

  if(values?.first_name){
    if(!validator.isAlpha(values.first_name)){
      errors.first_name = "Name cannot contain special characters or numbers"
    }
  }

  if(values?.last_name){
    if(!validator.isAlpha(values.last_name)){
      errors.last_name = "Name cannot contain special characters or numbers"
    }
  }

  if(!values.role){
    errors.role = "Please select the role of the user";
  }

  if(!values.email && (values.role==='M' || values.role==='C' || values.role==='A')){
    errors.email = "Email id is required for the selected role"
  }

  if(values?.email){
    if(!validator.isEmail(values?.email)){
      errors.email = "Please enter a valid email"
    }
  }

  if(!values.branch){
    errors.branch = "Please enter the branch name"
  }

  if(!values.city){
    errors.city = "Please enter the city"
  }

  if(values.role === 'F'){
    if(!values.country_code || !values.phone_number){
      errors.phone_number = "Contact information is required for selected role"
    }
    // errors.email = null;
    // errors.password = null;
    // errors.password1 = null;
  }

  

  if (values.phone_number) {
    if (isNaN(values.phone_number)) errors.phone_number = 'Please enter valid phone number';
    if (!values.country_code) errors.country_code = 'Please select country code';

    const mappingValidation = PHONE_VALIDATE.filter((item) => item.value === values.country_code)[0];
    if (mappingValidation && values.phone_number.length !== mappingValidation?.validate?.length) {
      errors.phone_number = 'Phone number cannot be of '+values.phone_number.length+' digits'
    }
  }

  if(!values.remarks){
    errors.remarks = "Please enter some remarks, e.g. time of request or request id"
  }

  return errors
}
export const resetPasswordValidate = (values) =>{
  const errors = {};

  if(values.password){
    if(values.password.length<8){
      errors.password = "Password should have atleast 8 characters"
    }
    if(!values.password1){
      errors.password1 = "Re-enter your password for confirmation";
    }
    if(values.password && values.password1){
       if(!validator.equals(values.password1,values.password)){
      errors.password1 = "Passwords do not match"
    }}
  }
  if(!values.remarks){
    errors.remarks = "Please enter some remarks, e.g. time of request or request id"
  }
  return errors;
}
export const updateUserValidate = (values)=>{
  const errors = {};

  if (values.phone_number) {
    if (isNaN(values.phone_number)) errors.phone_number = 'Please enter valid phone number';
    if (!values.country_code) errors.country_code = 'Please select country code';

    const mappingValidation = PHONE_VALIDATE.filter((item) => item.value === values.country_code)[0];
    if (mappingValidation && values.phone_number.length !== mappingValidation?.validate?.length) {
      errors.phone_number = 'Phone number cannot be of '+values.phone_number.length+' digits';
    }
  }

  if(values?.email){
    if(!validator.isEmail(values?.email)){
      errors.email = "Please enter a valid email"
    }
  }

  if(!values.remarks){
    errors.remarks = "Please enter some remarks, e.g. time of request or request id"
  }
  return errors;
}

export const CsMappingValidate = (values) => {
  const errors = {};
  if(!values.customer_name){
    errors.customer_name= "Please enter name of the customer";
  }

  if(!values.acct_number){
    errors.acct_number = "Please enter the account number";
  }

  if(!values.cs_name){
    errors.cs_name= "Please enter name of the collection specialist";
  }

  if(!values.remarks){
    errors.remarks = "Please enter some remarks, e.g. time of request or request id"
  }
  return errors;
}

export const validate = (values) => {
  const errors = {};

  const note = values.payment_note;

  if (!values.payment_note) {
    errors.payment_note = 'Please select payment note';
  }

  const cashReceived = +values.cash_received?.split(',').join('');

  if (!values.cash_received && note !== PROMISE_TO_PAY) {
    errors.cash_received = 'Please enter cash received';
  }
  if (note === FULL_PAYMENT) {
    if (cashReceived === 0) {
      errors.cash_received = 'Cash Received can not be zero';
    } else if (cashReceived < values.total_expected) {
      errors.cash_received = 'Cash Received must be equal or greater than Total Expected Amount.';
    }
  }

  if (values.cash_received && checkAmountCurrency(cashReceived)) {
    errors.cash_received = 'Please check amount received.';
  }

  if (values.phone_number) {
    if (isNaN(values.phone_number)) errors.phone_number = 'Please enter valid phone number';
    if (!values.country_code) errors.country_code = 'Please select country code';

    const mappingValidation = PHONE_VALIDATE.filter((item) => item.value === values.country_code)[0];
    if (mappingValidation && values.phone_number.length !== mappingValidation?.validate?.length) {
      errors.phone_number = 'Please enter valid phone number';
    }
  }

  if (note === PARTIAL_PAYMENT) {
    if (cashReceived === 0) {
      errors.cash_received = 'Cash received can not be zero.';
    }
    if (cashReceived >= values.total_expected) {
      errors.cash_received = 'Partial Payment cash must be lesser than total expected amount.';
    }
  }

  if (values.is_ptp) {
    if (!values.ptp_amount) {
      errors.ptp_amount = 'Please enter promise to pay amount.';
    }

    if (values.ptp_amount && checkAmountCurrency(values.ptp_amount)) {
      errors.ptp_amount = 'Please check amount received.';
    }
    if (!values.ptp_date) {
      errors.ptp_date = 'Please enter promise to pay date.';
    }
    if (!values.ptp_location) {
      errors.ptp_location = 'Please enter promise to pay location.';
    }
    if (!values.zipcode) {
      errors.zipcode = 'Please enter zipcode.';
    } else if (values?.zipcode?.length !== 4) {
      errors.zipcode = 'Philippines zipcode should have 4 characters. Ex: 0700, 1234';
    }
    if (!values.city) {
      errors.city = 'Please enter city.';
    }
    if (!values.province) {
      errors.province = 'Please enter province.';
    }
    if (!values.cust_address2) {
      errors.cust_address2 = 'Please enter address.';
    }

    if (values.ptp_location === 'Field' && !values.ptp_collection_address) {
      errors.ptp_collection_address = 'Please enter collection address.';
    }
  }
  return errors;
};

export const custValidate = (values, cities) => {
  const errors = {};
  if (values.phone_number) {
    if (isNaN(values.phone_number)) errors.phone_number = 'Please enter valid phone number';
    if (!values.country_code) errors.country_code = 'Please select country code';

    const mappingValidation = PHONE_VALIDATE.filter((item) => item.value === values.country_code)[0];
    if (mappingValidation && values.phone_number.length !== mappingValidation?.validate?.length) {
      errors.phone_number = 'Phone number can be of '+values.phone_number.length+' digits';
    }
  }
  
  if (values.province) {
    if (!values.city) {
      errors.city = 'Please select city';
    }
  }
  return errors;
};
