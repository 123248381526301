/* eslint-disable no-console */
import { createContext } from 'react';
import { NEPAL_TZ, TVS_FORMAT_DATE } from '../constant';
import moment from 'moment';
import NepaliDate from 'nepali-date-converter';

export const RecordPaymentContext = createContext({
  showOtp: false,
  toggleOtp: () => {},
  currency: '',
  loading: false,
  sendOtp: (cc, cp) => {},
  totalExpected: 0,
  paid: 0,
});

export const DashboarContext = createContext({
  role: '',
  branch: '',
  loading: false,
});

export const recordPaymentDefaultValue = {
  payment_note: 'Full Payment',
  cash_received: '',
  remarks: '',
  payment_otp: '',
  ptp_date: '',
  total_expected: 0,
  is_ptp: false,
  ptp_amount: '',
  ptp_location: '',
  ptp_collection_address: '',
  is_different_address: false,
  country_code: '',
  cust_phone: '',
  cust_address2: '',
  cust_address2_type: '',
  is_not_provide_phone: false,
  phone_number_relationship: 'Customer',
  otp: '',
  zipcode: '',
  city: '',
  province: '',
};

export const AppContext = createContext({
  month: '',
  year: '',
  setYear: (m) => {
    console.log(m);
  },
  setMonth: (y) => {
    console.log(y);
  },
});

export const convertTimestamp = (time) => {
  const usTime = moment(time).format(TVS_FORMAT_DATE);
  return convertToNepaliDateTimeFormat(usTime, 'DD/MM/YYYY');
};

export const convertToNepaliDateTimeFormat = (time, format) => {
  if (!time || time === 'Invalid date') return '-';
  if (NEPAL_TZ === 'Asia/Kathmandu') {
    const d = moment(time, TVS_FORMAT_DATE).format('Y-MM-DD');
    const t = new NepaliDate(new Date(d));
    return t.format(format || 'DD/MM/YYYY');
  }

  return moment(time, TVS_FORMAT_DATE).format(format || 'DD/MM/YYYY');
};
