import {
    FETCH_STARTUP, FETCH_STARTUP_ADDRESS_SUCCESS, FETCH_STARTUP_ADDRESS_FAILED,
    FETCH_STARTUP_BRANCH_SUCCESS, FETCH_STARTUP_BRANCH_FAILED,
    FETCH_CS_FOR_MAPPING_FAILED, FETCH_CS_FOR_MAPPING_SUCCESS, FETCH_MANAGER_LIST_SUCCESS, FETCH_MANAGER_LIST_FAILED,
    UPDATE_TOKEN
} from "./types";
import axios from "axios";
import { BaseURL } from "../constant";
import setAuthToken from "../utils/setAuthToken"

export const getStartupAddressData = () =>
    async dispatch => {
        dispatch({ type: FETCH_STARTUP });
        try {
            const res = await axios.get(BaseURL + '/admin/getStartupData');
            dispatch({
                type: FETCH_STARTUP_ADDRESS_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            if (err.response.status == 401 && err.response.data.message == "Unauthorized") {
                const config = {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
                const token = localStorage.token
                const body = JSON.stringify({ token })
                var res = {}
                res = await axios.post(BaseURL + '/updateToken', body, config);
                if (res.status == 200) {
                    dispatch({
                        type: UPDATE_TOKEN,
                        payload: res.data
                    })
                    if (localStorage.token) {
                        setAuthToken(localStorage.token);
                    }
                    dispatch({ type: FETCH_STARTUP });
                    try {
                        const res = await axios.get(BaseURL + '/admin/getStartupData');
                        dispatch({
                            type: FETCH_STARTUP_ADDRESS_SUCCESS,
                            payload: res.data
                        })
                    } catch (err) {
                        dispatch({
                            type: FETCH_STARTUP_ADDRESS_FAILED,
                            payload: err.response.data.message
                        })
                    }
                } else {
                    dispatch({
                        type: FETCH_STARTUP_ADDRESS_FAILED,
                        payload: err.response.data.message
                    })
                }
            } else {
                dispatch({
                    type: FETCH_STARTUP_ADDRESS_FAILED,
                    payload: err.response.data.message
                })
            }
        }
    }

export const getBranchList = () =>
    async dispatch => {
        dispatch({ type: FETCH_STARTUP });

        try {
            const res = await axios.get(BaseURL + '/admin/getBranchList');
            dispatch({
                type: FETCH_STARTUP_BRANCH_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            if (err.response.status == 401 && err.response.data.message == "Unauthorized") {
                const config = {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
                const token = localStorage.token
                const body = JSON.stringify({ token })
                var res = {}
                res = await axios.post(BaseURL + '/updateToken', body, config);
                if (res.status == 200) {
                    dispatch({
                        type: UPDATE_TOKEN,
                        payload: res.data
                    })
                    if (localStorage.token) {
                        setAuthToken(localStorage.token);
                    }
                    dispatch({ type: FETCH_STARTUP });
                    try {
                        const res = await axios.get(BaseURL + '/admin/getBranchList');
                        dispatch({
                            type: FETCH_STARTUP_BRANCH_SUCCESS,
                            payload: res.data
                        })
                    } catch (err) {
                        dispatch({
                            type: FETCH_STARTUP_BRANCH_FAILED,
                            payload: err.response.data.message
                        })
                    }
                } else {
                    dispatch({
                        type: FETCH_STARTUP_BRANCH_FAILED,
                        payload: err.response.data.message
                    })
                }
            } else {
                dispatch({
                    type: FETCH_STARTUP_BRANCH_FAILED,
                    payload: err.response.data.message
                })
            }
        }
    }

export const getCsListForMapping = () =>
    async dispatch => {
        dispatch({ type: FETCH_STARTUP })
        try {
            const res = await axios.get(BaseURL + '/admin/getCsList');
            dispatch({
                type: FETCH_CS_FOR_MAPPING_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            if (err.response.status == 401 && err.response.data.message == "Unauthorized") {
                const config = {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
                const token = localStorage.token
                const body = JSON.stringify({ token })
                var res = {}
                res = await axios.post(BaseURL + '/updateToken', body, config);
                if (res.status == 200) {
                    dispatch({
                        type: UPDATE_TOKEN,
                        payload: res.data
                    })
                    if (localStorage.token) {
                        setAuthToken(localStorage.token);
                    }
                    dispatch({ type: FETCH_STARTUP })
                    try {
                        const res = await axios.get(BaseURL + '/admin/getCsList');
                        dispatch({
                            type: FETCH_CS_FOR_MAPPING_SUCCESS,
                            payload: res.data
                        })
                    } catch (err) {
                        dispatch({
                            type: FETCH_CS_FOR_MAPPING_FAILED,
                            payload: err.response.data.message
                        })
                    }
                } else {
                    dispatch({
                        type: FETCH_CS_FOR_MAPPING_FAILED,
                        payload: err.response.data.message
                    })
                }
            } else {
                dispatch({
                    type: FETCH_CS_FOR_MAPPING_FAILED,
                    payload: err.response.data.message
                })
            }
        }

    }

export const getManagerList = () =>
    async dispatch => {
        dispatch({ type: FETCH_STARTUP })
        try {
            const res = await axios.get(BaseURL + '/admin/getManagerList');
            dispatch({
                type: FETCH_MANAGER_LIST_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            if (err.response.status == 401 && err.response.data.message == "Unauthorized") {
                const config = {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
                const token = localStorage.token
                const body = JSON.stringify({ token })
                var res = {}
                res = await axios.post(BaseURL + '/updateToken', body, config);
                if (res.status == 200) {
                    dispatch({
                        type: UPDATE_TOKEN,
                        payload: res.data
                    })
                    if (localStorage.token) {
                        setAuthToken(localStorage.token);
                    }
                    dispatch({ type: FETCH_STARTUP })
                    try {
                        const res = await axios.get(BaseURL + '/admin/getManagerList');
                        dispatch({
                            type: FETCH_MANAGER_LIST_SUCCESS,
                            payload: res.data
                        })
                    } catch (err) {
                        dispatch({
                            type: FETCH_MANAGER_LIST_FAILED,
                            payload: err.response.data.message
                        })
                    }
                } else {
                    dispatch({
                        type: FETCH_MANAGER_LIST_FAILED,
                        payload: err.response.data.message
                    })
                }
            } else {
                dispatch({
                    type: FETCH_MANAGER_LIST_FAILED,
                    payload: err.response.data.message
                })
            }
        }
    }