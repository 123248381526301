import axios from "axios";

const setAuthToken = token =>{
    if(token){
        axios.defaults.headers.common['Authorization'] = "Bearer "+token;    //To convert it into a bearer token used by express jwt
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
}

export default setAuthToken;