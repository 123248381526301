import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Nav, NavItem } from 'reactstrap';
import styles from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../actions/auth';
import { useLocation } from 'react-router';
import wheeltek_logo from '../../img/Wheeltek Logo.png'
import jagdamba_logo from '../../img/jagdamba.png'

const Header = ({logout,auth,history}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
//   const dispatch = useDispatch();
  const toggle = () => setDropdownOpen((prevState) => !prevState);


  const handleLogout = () => {
    logout();
    history.push('/login');
  };
const location = useLocation();
  return (
    auth.isAuthenticated &&
    <Div>
      
        <div className="d-flex justify-content-between">
          <Link to="/">
            <img src={jagdamba_logo} alt="jagdamba" height="44" />
          </Link>
         
          <Nav>
            <NavItem  className={location?.pathname==='/getCustomerList' ? 'active' : ''}>
                <LinkStyle to="/getCustomerList">Customers</LinkStyle>
            </NavItem>
            <NavItem className={location?.pathname==='/specialist'? 'active' : ''}>
                <LinkStyle to="/specialist">Collection Specialists</LinkStyle>
            </NavItem>
            <NavItem className={location?.pathname==='/cashier' ? 'active' : ''}>
                <LinkStyle to="/cashier">Cashiers</LinkStyle>
            </NavItem>
            <NavItem className={location?.pathname==='/manager'? 'active' : ''}>
                <LinkStyle to="/manager">Managers</LinkStyle>
              </NavItem>
            <NavItem className={location?.pathname==='/corporateuser'? 'active' : ''}>
                <LinkStyle to="/corporateuser">Corporate Users</LinkStyle>
            </NavItem>
            <NavItem className={location?.pathname === '/send-credential' ? 'active' : ''}>
            <LinkStyle to="/send-credential">Credentials</LinkStyle>
          </NavItem>
          </Nav>
          <>
            <DropdownStyle isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggleType>
                <div className="d-flex align-items-center">
                  <div className="header-avatar" />
                  {auth?.user?.first_name} {auth?.user?.last_name}
                  <div className={!dropdownOpen ? 'pb-1' : ''}>
                    {!dropdownOpen ? (
                      <div className="ml-4 header-arrow-down" />
                    ) : (
                      <div className="ml-4 header-arrow-up" />
                    )}
                  </div>
                </div>
              </DropdownToggleType>
              <DropdownMenu container="body">
                <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
              </DropdownMenu>
            </DropdownStyle>
          </>
        </div>
    </Div>
  );
};
const LinkStyle = styles(Link)`
  margin-right: 30px;
  color: #fff;
  textDecoration: none;
  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
}
`;
const Div = styles.div`
  background: #2A2A3E;
  padding: 5px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
`;
const DropdownStyle = styles(Dropdown)`
  font-weight: 400;
  &:hover, &:focus, &:visited {
    background: transparent;
    border: none;
    box-shadow: none;
    outline: 0
  }
`;
const DropdownToggleType = styles(DropdownToggle)`
  background: transparent;
  border: none;
  border-radius: 0;
  font-family: 'Roboto', sans-serif;
  &:hover, &:focus, &:visited, &:active {
    border: none !important;
    background: transparent !important;
    border: none;
    box-shadow: none  !important;
    outline: 0;
  }
`;

Header.propTypes = {
    logout : PropTypes.func.isRequired,
    auth : PropTypes.object.isRequired,
    }
    
    const mapStateToProps = state =>({
      auth: state.auth   //whtever is required from reducer
    })
    export default connect(mapStateToProps,{logout})(withRouter(Header));
