/* eslint-disable react/require-default-props */
import React from 'react';
import DatePicker from 'react-datepicker';
import { Label, FormGroup } from 'reactstrap';
import { useFormikContext } from 'formik';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import styles from 'styled-components';
import PropTypes from 'prop-types';
import calendar from '../../img/calendar.png'



const TvsDatepicker = ({ id, label, labelStyle, inputIcon, placeholder, disabled, maxDate, minDate,due_date }) => {
  const { errors, touched, values, setValues } = useFormikContext();

  const inputTouched = touched[id] || false;
  const inputErrors = errors[id] || false;

  return (
    <FormGroup className="position-relative">
      {label && <Label style={labelStyle}>{label}</Label>}
      <DatePickerStyle
        onChange={(date) => setValues({ ...values, [id]: moment(date).format('MM/DD/y') })}
        id={id}
        maxDate={maxDate}
        minDate={minDate}
        selected={values[id] ? new Date(values[id]) : null}
        dateFormat="dd/MM/y"
        placeholderText={placeholder}
      />
      <Icon>
        <img src={calendar} alt="calendar" width="16" height="20" alignment='centre' />
      </Icon>
      {inputTouched && inputErrors && (
        <div className="form-text text-danger text-small">{inputTouched && inputErrors}</div>
      )}
    </FormGroup>
  );
};

const DatePickerStyle = styles(DatePicker)`
  height: 50px;
  border: 1px solid #ced4da;
  width: 100%;
  padding: 10px;
  border-radius: 2px;
`;

const Icon = styles.div`
  position: absolute;
  top: 40px;
  bottom: 0;
  right: 10px;
`;

TvsDatepicker.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelStyle: PropTypes.any,
  inputIcon: PropTypes.element,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  maxDate: PropTypes.any,
  minDate: PropTypes.any,
  due_date: PropTypes.any,
}
export default TvsDatepicker;
