
import React, { useEffect, useState } from 'react';
import { Container, Label } from 'reactstrap';
// import { useDispatch, useSelector } from 'react-redux';
// import { State } from 'redux/types';
import Boundary from '../../components/layouts/Boundary';
import {Table} from '../../components/elements';
import styles from 'styled-components';
import { getCustomerList } from '../../actions/fetchCollection';
import { getCustomerListingCols } from './columns';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Card, CardBody } from 'reactstrap';
import { DebounceInput } from 'react-debounce-input';
import { Link } from 'react-router-dom';
import moment from 'moment';

const CustomerListingContainer = ({collections, getCustomerList}) => {
  const [state, setState] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const [tableState, setTableState] = useState({
    filter: {
      search:''
    },
    page: 1,
    sizePerPage: 20,
  });

  const [sort, setSort] = useState({
    s: '',
    o: '',
  });

  const [sortState,setSortState] = useState({
    sales:'',
    full_name:'',
  })
  //const {crudState} = useSelector((st) => st); //use states

  useEffect(() => {
        getCustomerList(tableState);
  }, [tableState]);

  const onTableChange = (type, value) => {
    if (type === 'pagination') {
      setTableState({
        ...tableState,
        page: value.page,
      });
    } else if( type === 'search') {
        setTableState({
            ...tableState,
            page:1,
            filter : {
                ...tableState.filter,
                search : value
            }
        })
    }
    if (type === 'sort') {
      const sortField=value.sortField;
      var sortOrder='desc';
      if(sortState[sortField]!==''){
        if(sortState[sortField]==='desc'){
          sortOrder = 'asc'
        }
      }
      sortState[sortField]=sortOrder
      setSortState({
        ...sortState
      })
      setSort({
        s: value.sortField,
        o: sortOrder,
      });
      setTableState({
        ...tableState,
        page:1,
        filter : {
            ...tableState.filter,
            sort : sort
        }
    })
    }
  };

  useEffect(() => {
    setLoading(collections?.pending || false);
    setState(collections?.data || []);
    setTotal(collections?.otherInfo?.count || 0);
  }, [collections]);

  

  const handleChangeBranch = (item) => {
    setTableState({
      ...tableState,
      filter: {
        ...tableState.filter,
        branch: item,
      },
    });
  };

  return (
      <>
      <Container>
        <div className="pt-4 pb-2">
          <h1>List of Customers</h1>
          <H3>As of today, {`${moment().format('DD MMM y')} at ${moment().format('h:mma')}`}</H3>

        </div>

        <Card className="my-4">
          <CardBody>
            <Label
              style={{
                color: '#2A2A3E',
              }}
            >
              Search by Name, account id, phone number or zipcode
              <span style={{ color: '#2A2A3E', opacity: 0.5, marginLeft: '10px' }}>(Minimum 3 characters)</span>
            </Label>
            <div className="position-relative">
              <DebounceInput
                minLength={3}
                debounceTimeout={500}
                onChange={(event) => onTableChange('search', event.target.value)}
                placeholder="Type here"
                className="form-control"
                value={state?.filter?.search || ''}
              />
              {state?.filter?.search?.length >= 3 && (
                <Button
                  color="x"
                  onClick={() => onTableChange('search', '')}
                  className="position-absolute circle-close"
                  style={{ width: '20px', height: '20px', top: '15px', right: '15px' }}
                />
              )}
            </div>
          </CardBody>
        </Card>
        <Boundary loading={loading}>
          <>
            <div className="table-note">
              <Label className="label-color">
                {total > tableState.sizePerPage * tableState.page ? tableState.sizePerPage * tableState.page : total} of {total} entries
              </Label>
            </div>
            <div id = "customerTable">
              <Table
                data={state}
                page={tableState.page}
                columns={getCustomerListingCols()}
                sizePerPage={tableState.sizePerPage}
                totalSize={total}
                onTableChange={onTableChange}
                keyField="id"
              />
            </div>
          </>
        </Boundary>
      </Container>
    </>
  );
};

const H3 = styles.h3`
  opacity: 0.8;
`;
CustomerListingContainer.propTypes = {
    collections: PropTypes.object.isRequired,
    getCustomerList : PropTypes.func.isRequired,
  }

const mapStateToProps = state =>({
    collections: state.collections,    //whtever is required from reducer
})
export default connect(mapStateToProps,{getCustomerList})(CustomerListingContainer);

