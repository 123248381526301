export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED= "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
export const LOGOUT = "LOGOUT";
export const UPDATE_TOKEN = "UPDATE_TOKEN"
export const FETCH_USER_LIST = "FETCH_USER_LIST";
export const FETCH_USER_LIST_SUCCESS = "FETCH_USER_LIST_SUCCESS";
export const FETCH_USER_LIST_FAILED = "FETCH_USER_LIST_FAILED";
export const FETCH_CUSTOMER_LIST = "FETCH_CUSTOMER_LIST";
export const FETCH_CUSTOMER_LIST_SUCCESS = "FETCH_CUSTOMER_LIST_SUCCESS";
export const FETCH_CUSTOMER_LIST_FAILED = "FETCH_CUSTOMER_LIST_FAILED";

export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILED = "FETCH_USER_FAILED";
export const FETCH_CUSTOMER = "FETCH_CUSTOMER";
export const FETCH_CUSTOMER_SUCCESS = "FETCH_CUSTOMER_SUCCESS";
export const FETCH_CUSTOMER_FAILED = "FETCH_CUSTOMER_FAILED";

export const FETCH_PAYMENT_DETAIL = "FETCH_PAYMENT_DETAIL";
export const FETCH_PAYMENT_DETAIL_SUCCESS = "FETCH_PAYMENT_DETAIL_SUCCESS";
export const FETCH_PAYMENT_DETAIL_FAILED = "FETCH_PAYMENT_DETAIL_FAILED";

export const FETCH_STARTUP = "FETCH_STARTUP";
export const FETCH_STARTUP_ADDRESS_SUCCESS = "FETCH_STARTUP_ADDRESS_SUCCESS";
export const FETCH_STARTUP_BRANCH_SUCCESS = "FETCH_STARTUP_BRANCH_SUCCESS";
export const FETCH_STARTUP_ADDRESS_FAILED = "FETCH_STARTUP_ADDRESS_FAILED";
export const FETCH_STARTUP_BRANCH_FAILED = "FETCH_STARTUP_ADDRESS_FAILED";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";

export const FETCH_CASHIER = "FETCH_CASHIER";
export const FETCH_CASHIER_SUCCESS = "FETCH_CASHIER_SUCCESS";
export const FETCH_CASHIER_FAILED = "FETCH_CASHIER_FAILED";

export const FETCH_CS = "FETCH_CS";
export const FETCH_CS_SUCCESS = "FETCH_CS_SUCCESS";
export const FETCH_CS_FAILED = "FETCH_CS_FAILED";

export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_FAILED = "UPDATE_CUSTOMER_FAILED";

export const CREATE_USER = "CREATE_USER";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILED = "CREATE_USER_FAILED";


export const FETCH_CS_FOR_MAPPING_SUCCESS = "FETCH_CS_FOR_MAPPING_SUCCESS";
export const FETCH_CS_FOR_MAPPING_FAILED = "FETCH_CS_FOR_MAPPING_FAILED";
export const ASSIGN_CS = "ASSIGN_CS";
export const ASSIGN_CS_SUCCESS = "ASSIGN_CS_SUCCESS";
export const ASSIGN_CS_FAILED = "ASSIGN_CS_FAILED";

export const FETCH_MANAGER_LIST_SUCCESS = "FETCH_MANAGER_LIST_SUCCESS";
export const FETCH_MANAGER_LIST_FAILED = "FETCH_MANAGER_LIST_FAILED";

export const DEACTIVATE_USER = "DEACTIVATE_USER";
export const DEACTIVATE_USER_SUCCESS = "DEACTIVATE_USER_SUCCESS";
export const DEACTIVATE_USER_FAILED= "DEACTIVATE_USER_FAILED";

export const REACTIVATE_USER = "REACTIVATE_USER";
export const REACTIVATE_USER_SUCCESS = "REACTIVATE_USER_SUCCESS";
export const REACTIVATE_USER_FAILED= "REACTIVATE_USER_FAILED";

export const RESET_PHONE_NUMBER_USER = "RESET_PHONE_NUMBER_USER";
export const RESET_PHONE_NUMBER_USER_SUCCESS = "RESET_PHONE_NUMBER_USER_SUCCESS";
export const RESET_PHONE_NUMBER_USER_FAILED = "RESET_PHONE_NUMBER_USER_FAILED";

export const SEND_USER_CREDENTIAL = "SEND_USER_CREDENTIAL";
export const SEND_USER_CREDENTIAL_SUCCESS = "SEND_USER_CREDENTIAL_SUCCESS";
export const SEND_USER_CREDENTIAL_FAILED = "SEND_USER_CREDENTIAL_FAILED";

export const SEND_NEWUSER_EMAIL = "SEND_NEWUSER_EMAIL"
export const SEND_NEWUSER_EMAIL_SUCCESS = "SEND_NEWUSER_EMAIL_SUCCESS"
export const SEND_NEWUSER_EMAIL_FAILED = "SEND_NEWUSER_EMAIL_FAILED"

export const CLEAR_ACTION_STATUS = "CLEAR_ACTION_STATUS";