import { Link } from 'react-router-dom';
import React from 'react';
import { ACCURACY_MAPPING } from '../../constant';
import { sortCaret } from '../../components/elements/Table';
import { convertToNepaliDateTimeFormat, convertTimestamp } from '../../helpers/index';
import moment from 'moment';
import { BE_FORMAT_DATE, TVS_FORMAT_DATE } from '../../constant/index';

const mappingAccuracy = (visit_distance) => {
    if (visit_distance === null || visit_distance === undefined) {
        return <div style={{ color: ACCURACY_MAPPING?.LOW?.color }}>{ACCURACY_MAPPING?.LOW?.text}</div>;
    }
    else if (visit_distance < 2) {
        return <div style={{ color: ACCURACY_MAPPING?.HIGH?.color }}>{ACCURACY_MAPPING?.HIGH?.text}</div>;
    }
    else if (visit_distance >= 2 && visit_distance <= 5) {
        return <div style={{ color: ACCURACY_MAPPING?.MED?.color }}>{ACCURACY_MAPPING?.MED?.text}</div>;
    } else {
        return <div style={{ color: ACCURACY_MAPPING?.LOW?.color }}>{ACCURACY_MAPPING?.LOW?.text}</div>;
    }
};

export const getCSPaymentListingCols = (sort) => [
    {
        dataField: 'payment.rec_date',
        text: 'Timestamp',
        order: ['asc', 'desc'],
        sortCaret: (order, column) => sortCaret(column, sort, order),
        formatter: (cell, row) => (
            <Link to={`/collection-detail/${row?.payment?.id}`}>
                <ins>
                    <div>{convertTimestamp(row?.payment?.rec_date)}</div>
                    <div>{moment(cell).format('hh:mm A')}</div>
                </ins>
            </Link>
        )
    },
    {
        dataField: 'payment.acct_number',
        text: 'Account Number',
    },
    {
        dataField: 'payment.address_2',
        text: 'Customer Address',
        formatter: (cell, row) => (
            cell?.length > 0 ? cell : row?.payment?.s?.customers?.address),
    },
    {
        dataField: 'payment.visit_distance',
        text: 'Location Conformance',
        formatter: (cell, row) => (
            cell ? <div>{mappingAccuracy(cell)}</div> : '-'),
    },
    {
        dataField: 'due.month_due_date',
        text: 'Due Date',
        formatter: (cell) =>
            cell ? convertToNepaliDateTimeFormat(moment(cell, BE_FORMAT_DATE).format(TVS_FORMAT_DATE), 'DD/MM/YYYY') : '-',
    },
    {
        dataField: 'payment.total_expected_amt',
        text: 'Total Due *'
    },
    {
        dataField: 'payment.penalty_received',
        text: 'Cash Received *',
        formatter: (cell, row) =>
            cell ? (+cell + + row.payment.principal_received).toFixed(2) : '0.00'
    },
    {
        dataField: 'payment.payment_note',
        text: 'Payment Reason',
    },
    {
        dataField: 'payment.ptp_date',
        text: 'PTP Date',
        formatter: (cell, row) => (
            <span>{row.payment.ptp_date !== null ? moment(row.payment.ptp_date).format('DD/MM/YYYY') : '-'}</span>
        )
    }
];
