import { Link } from 'react-router-dom';
import React from 'react';
import { sortCaret } from '../../components/elements/Table';
import { convertToNepaliDateTimeFormat, convertTimestamp } from '../../helpers/index';
import moment from 'moment';
import { BE_FORMAT_DATE, TVS_FORMAT_DATE } from '../../constant/index';

export const getCashierPaymentListingCols = (sort) => [
    {
        dataField: 'rec_date',
        text: 'Timestamp',
        order: ['asc', 'desc'],
        sortCaret: (order, column) => sortCaret(column, sort, order),
        formatter: (cell, row) => (
            <Link to={`/collection-detail/${row.div}`}>
                <ins>
                    <div>{convertTimestamp(row.rec_date)}</div>
                    <div>{moment(cell).format('hh:mm A')}</div>
                </ins>
            </Link>
        )
    },
    {
        dataField: 'acct_number',
        text: 'Account Number',
    },
    {
        dataField: 'd.month_due_date',
        text: 'Due Date',
        formatter: (cell) =>
            cell ? convertToNepaliDateTimeFormat(moment(cell, BE_FORMAT_DATE).format(TVS_FORMAT_DATE), 'DD/MM/YYYY') : '-',
    },
    {
        dataField: 'd.expected_amt',
        text: 'Total Due *',
        formatter: (cell, row) => 
            cell ? cell : '-',
    },
    {
        dataField: 'rec_amt',
        text: 'Cash Received *'
    },
    {
        dataField: 'payment_note',
        text: 'Payment Note'
    },
    {
        dataField: 'ptp_date',
        text: 'PTP Date',
        formatter: (cell, row) => (
            <span>{row.ptp_date !== null ? convertToNepaliDateTimeFormat(row.ptp_date, TVS_FORMAT_DATE) : '-'}</span>
        )
    }

];
