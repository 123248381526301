import React, {useEffect} from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import styles from 'styled-components';
import PrivateRoute from './components/routers/PrivateRoute';
//Components
import Header from './components/layouts/Header';
import Footer from './components/layouts/Footer';
import login from './containers/login';
import CustomerList from './containers/customer_table/list';
import  UserList from './containers/user_table/list';
import CustomerUpdateForm from './containers/update_forms/CustomerUpdateForm'
import UserUpdateForm from './containers/update_forms/UserUpdateForm';
import CashierDetail from './containers/cashier/detail';
import SpecialistDetail from './containers/specialist/detail';
import CollectionDetail from './containers/collection/detail';
import SendCredential from './containers/credentials/send';
import createUser from './containers/createUser';
//Redux
import { Provider } from 'react-redux';
import store  from './store.js';
import setAuthToken from "./utils/setAuthToken"
import { loadUser } from './actions/auth';
import ReduxToastr from 'react-redux-toastr';
import PasswordChangeForm from './containers/update_forms/PasswordChangeForm';

if(localStorage.token){
  setAuthToken(localStorage.token);
}

const App = () => {

  useEffect(() =>{
    store.dispatch(loadUser())
  },[]);

  return (
    <Provider store={store}>
     <Router>
     <Main>
      <Header />
      <Route exact path='/' component={login} /> 
      <div
        style={{
          marginTop: '20px',
        }}
      >
        </div>
        <Switch>
        <Route exact path='/login' component={login}/> 
        <PrivateRoute exact path='/getCustomerList' component={CustomerList}/>
        <PrivateRoute exact path='/getUserList' component={UserList}/>
        <PrivateRoute exact path='/corporateuser' component={UserList}/>
        <PrivateRoute exact path='/manager' component={UserList}/>
        <PrivateRoute exact path='/cashier' component={UserList}/>
        <PrivateRoute exact path='/specialist' component={UserList}/>
        <PrivateRoute exact path='/customer-detail/:id' component={CustomerUpdateForm} />
        <PrivateRoute exact path='/user-detail/:id' component={UserUpdateForm} />
        <PrivateRoute exact path='/cashier-detail/:id' component={CashierDetail} />
        <PrivateRoute exact path='/specialist-detail/:id' component={SpecialistDetail} />
        <PrivateRoute exact path='/collection-detail/:id' component={CollectionDetail}/>
        <PrivateRoute exact path='/createUser' component={createUser}/>
        <PrivateRoute exact path='/user-detail/change-password/:id' component={PasswordChangeForm} />
        <PrivateRoute exact path='/send-credential' component={SendCredential}/>
        </Switch>
        <ReduxToastr
          timeOut={6000}
          newestOnTop={false}
          preventDuplicates
          position="top-left"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick={true}
        />
        <Footer />
        </Main>
    </Router>
    </Provider>
  );
}

const Main = styles.div`
  background-color: #f5f9fb;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh
`;
export default App;
