import axios from 'axios';

import {
    REGISTER_SUCCESS, REGISTER_FAIL, USER_LOADED, AUTH_ERROR,
    LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, LOGOUT_FAIL
} from './types';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken';
import { BaseURL } from '../constant';

export const loadUser = () => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const res = await axios.get(BaseURL + '/auth');

        dispatch({
            type: USER_LOADED,
            payload: res.data
        })
    } catch (error) {
        dispatch({
            type: AUTH_ERROR
        });
    }
}
//Register user
export const register = ({ first_name, last_name, country_code, phone_number, email, password }) =>
    async dispatch => {
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        }

        const body = JSON.stringify({ first_name, last_name, country_code, phone_number, email, password });

        try {
            const res = await axios.post(BaseURL + '/signup', body, config);

            dispatch({
                type: REGISTER_SUCCESS,
                payload: res.data
            });
            dispatch(loadUser());
        } catch (err) {

            // const error = err.response;
            dispatch(setAlert(err.response.data.message, 'danger'));
            dispatch({
                type: REGISTER_FAIL
            })

        }
    }


//Login user
export const login = (email, password, history) =>
    async dispatch => {
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        }

        const body = JSON.stringify({ email, password });

        try {
            const res = await axios.post(BaseURL + '/login', body, config);

            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data
            });

            dispatch(loadUser());
            history.push('/getUserList');
        } catch (err) {

            // const error = err.response;
            dispatch(setAlert(err.response.data.message, 'danger'))
            dispatch({
                type: LOGIN_FAIL,
                payload: err.response.data.message,
            })
        }
    }

export const logout = () => dispatch => {
    dispatch({ type: LOGOUT });
}