import { combineReducers} from 'redux';
import alert from './alert';
import auth from './auth';
import collections from './collections';
import selectedById from './selectedById';
import crudActions from './crudActions';
import startup from './startup';
import { reducer as toastrReducer } from 'react-redux-toastr';
export default combineReducers({
alert,auth,collections, selectedById, crudActions,toastr: toastrReducer,startup
});