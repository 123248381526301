import {
    UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_FAILED, UPDATE_CUSTOMER,
    UPDATE_CUSTOMER_SUCCESS, UPDATE_CUSTOMER_FAILED, CREATE_USER, CREATE_USER_SUCCESS,
    CREATE_USER_FAILED, ASSIGN_CS, ASSIGN_CS_SUCCESS, ASSIGN_CS_FAILED, CLEAR_ACTION_STATUS,
    DEACTIVATE_USER, DEACTIVATE_USER_SUCCESS, DEACTIVATE_USER_FAILED,
    REACTIVATE_USER, REACTIVATE_USER_SUCCESS, REACTIVATE_USER_FAILED,
    SEND_USER_CREDENTIAL, SEND_USER_CREDENTIAL_SUCCESS, SEND_USER_CREDENTIAL_FAILED, SEND_NEWUSER_EMAIL, SEND_NEWUSER_EMAIL_SUCCESS, SEND_NEWUSER_EMAIL_FAILED
} from "../actions/types";

const initialState = {
    initiate: false,
    pending: false,
    loading: true,
    message: null,
    success: null,
    error: null
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case UPDATE_USER:
        case UPDATE_CUSTOMER:
        case CREATE_USER:
        case ASSIGN_CS:
        case DEACTIVATE_USER:
        case REACTIVATE_USER:
        case SEND_USER_CREDENTIAL:
        case SEND_NEWUSER_EMAIL:
            return ({
                ...state,
                initiate: true,
                pending: true
            })
        case UPDATE_CUSTOMER_SUCCESS:
        case UPDATE_USER_SUCCESS:
        case CREATE_USER_SUCCESS:
        case ASSIGN_CS_SUCCESS:
        case DEACTIVATE_USER_SUCCESS:
        case REACTIVATE_USER_SUCCESS:
        case SEND_USER_CREDENTIAL_SUCCESS:
        case SEND_NEWUSER_EMAIL_SUCCESS:
            return ({
                ...state,
                initiate: true,
                pending: false,
                loading: false,
                success: true,
                error: false,
                message: payload
            })
        case UPDATE_USER_FAILED:
        case UPDATE_CUSTOMER_FAILED:
        case CREATE_USER_FAILED:
        case ASSIGN_CS_FAILED:
        case DEACTIVATE_USER_FAILED:
        case REACTIVATE_USER_FAILED:
        case SEND_USER_CREDENTIAL_FAILED:
        case SEND_NEWUSER_EMAIL_FAILED:
            return ({
                ...state,
                initiate: true,
                pending: false,
                loading: false,
                success: false,
                error: true,
                message: payload
            })

        case CLEAR_ACTION_STATUS:
            return initialState;

        default:
            return state;
    }
}