import React from 'react';

import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types'


const Breadcrumb = ({ data }) => {
  return (
    <div
      style={{
        background: 'rgba(42, 42, 62, 0.75)',
        height: 40
      }}
    >
      <Container>
        <ul className="d-flex py-2 align-items-center tvs-breadcrumb">
          {data.map((item) => (
            <li className="mr-2" key={item.label}>
              {item?.link ? (
                <Link style={{textDecoration: "none"}} to={item.link} className="text-small">
                  {item.label}
                </Link>
              ) : (
                <div className="text-small">{item.label}</div>
              )}
            </li>
          ))}
        </ul>
      </Container>
    </div>
  );
};

Breadcrumb.propTypes = {
  data : PropTypes.object.isRequired,
}
export default Breadcrumb;
