import { Link } from 'react-router-dom';
import React from 'react';
import { sortCaret } from '../../components/elements/Table';

export const getCustomerListingCols = (sort) => [
    {
      dataField: 'sales',
      text: 'Account id',
      //sort: true,
      //sortCaret: (order, column) => sortCaret(column, sort),
      formatter: (cell, row) => (
        
         
            <span>{row.sales?.acct_number}</span>
          
        )
      },
     
 

  {
    dataField: 'full_name',
    text: 'Name',
    sort: true,
    sortCaret: (order, column) => sortCaret(column, sort),
    formatter: (cell, row) => (
      <Link to={`/customer-detail/${row.id}`} >
        <ins>
          {cell}
        </ins>
      </Link>
    ),
  },

  {
    dataField: 'phone_number',
    text: 'Phone Number',

    formatter: (cell, row) => (
        
         
      <span>{row?.country_code} {row?.new_phone_number? row.new_phone_number : row.phone_number}</span>
    
  )
  },

  {
    dataField: 'province',
    text: 'Province',
  },

  {
    dataField: 'city',
    text: 'City',
  },

  // {
  //   dataField: 'users',
  //   text: 'Branch',

  //   formatter: (cell, row) => (
        
         
  //     <span>{row.client_code!==0?row.users?.branch: "Unassigned"}</span>
    
  // )
  // },

  
  
  {
    dataField: 'zipcode',
    text: 'Postal code',
  },


  
];
