/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { CardBody, Card, Container, Label, Tooltip } from "reactstrap";
import { getPaymentDetailById } from "../../actions/fetchById";
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import styles from 'styled-components';
import { Breadcrumb } from "../../components/elements";
import { ACCURACY_MAPPING, CURRENCY_TYPE } from '../../constant/index';
import { withRouter } from "react-router-dom";
import moment from "moment";
import { convertTimestamp } from "../../helpers/index";

const CollectionDetail = ({
    match, getPaymentDetailById, user
}) => {
    const [data, setData] = useState({});

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    useEffect(() => {
        getPaymentDetailById(match?.params?.id)
        setData(user.data)
    }, [match?.params?.id])

    useEffect(() => {
        setData(user.data)
    }, [user.data])

    const mappingAccuracy = (visit_distance) => {
        if (visit_distance === null || visit_distance === undefined) {
            return <div style={{ color: ACCURACY_MAPPING?.LOW?.color }}>{ACCURACY_MAPPING?.LOW?.text}</div>;
        }
        else if (visit_distance < 2) {
            return <div style={{ color: ACCURACY_MAPPING?.HIGH?.color }}>{ACCURACY_MAPPING?.HIGH?.text}</div>;
        }
        else if (visit_distance >= 2 && visit_distance <= 5) {
            return <div style={{ color: ACCURACY_MAPPING?.MED?.color }}>{ACCURACY_MAPPING?.MED?.text}</div>;
        } else {
            return <div style={{ color: ACCURACY_MAPPING?.LOW?.color }}>{ACCURACY_MAPPING?.LOW?.text}</div>;
        }
    };

    return (user.data &&
        <div className="mt-0">
            <Breadcrumb
                data={[
                    {
                        label: 'User List',
                        link: '/',
                    },
                    {
                        label: 'Collection Detail',
                    },
                ]}
            />
            <Container>
                <div className="d-flex  justify-content-between">
                    <h1 className="mb-3">Collection Details
                        <div className="text-normal">at {convertTimestamp(data?.payment?.rec_date)} {moment(data?.payment?.rec_date).format('hh:mm A')}</div>
                    </h1>
                    <Label className="label-color align-self-center">* All currencies in {CURRENCY_TYPE}</Label>
                </div>
                <Card>
                    <CardBody>
                        <CollectionDetailStyle>
                            <div>
                                <Label className="label-color">Account Number</Label>
                                <p>{data?.payment?.acct_number}</p>
                            </div>
                            <div>
                                <Label className="label-color">Customer Name</Label>
                                <p>{data?.payment?.s?.customers?.full_name}</p>
                            </div>
                            <div>
                                <Label className="label-color">Phone Number (Database)</Label>
                                <p>{data?.payment?.s?.customers?.country_code} {data?.payment?.s?.customers?.phone_number}</p>
                            </div>
                            <div>
                                <Label className="label-color">Address (Database)</Label>
                                <p>{data?.payment?.s?.customers?.address}</p>
                            </div>
                        </CollectionDetailStyle>
                        <CollectionDetailStyle>
                            <div>
                                <Label
                                    className="label-color"
                                    id='locationConformance'
                                    style={{
                                        color: 'blue',
                                        textDecoration: 'underline'
                                      }}>
                                    Location Conformance
                                </Label>
                                <Tooltip placement='top' isOpen={tooltipOpen} target='locationConformance' toggle={toggle}>
                                    <div className="d-flex">
                                        <div className="mr-3">
                                            <div
                                                style={{
                                                    color: ACCURACY_MAPPING?.HIGH?.color,
                                                }}
                                            >
                                                {ACCURACY_MAPPING?.HIGH?.text}
                                            </div>
                                            <div
                                                style={{
                                                    color: ACCURACY_MAPPING?.MED?.color,
                                                }}
                                            >
                                                {ACCURACY_MAPPING?.MED?.text}
                                            </div>
                                            <div
                                                style={{
                                                    color: ACCURACY_MAPPING?.LOW?.color,
                                                }}
                                            >
                                                {ACCURACY_MAPPING?.LOW?.text}
                                            </div>
                                        </div>
                                        <div>
                                            <div>Less than 2 kms from address</div>
                                            <div>Between 2 kms - 5 kms from address</div>
                                            <div>Greater than 5 kms from address</div>
                                        </div>
                                    </div>
                                </Tooltip>
                                <p>{mappingAccuracy(data?.payment?.visit_distance)}</p>
                            </div>
                            <div>
                                <Label className="label-color">Geo Location</Label>
                                {data?.payment?.geo_lat ? <p>{`${data?.payment?.geo_lat}, ${data?.payment?.geo_lng}`}</p> : <p>-</p>}
                            </div>
                            <div>
                                <Label className="label-color">Phone Number (Field)</Label>
                                <p>{data?.payment?.phone_number ? `${data?.payment?.country_code} ${data?.payment?.phone_number}` : '-'}</p>
                            </div>
                            <div>
                                <Label className="label-color">Address (Field)</Label>
                                <p>{data?.payment?.address_2 || '-'}</p>
                            </div>
                        </CollectionDetailStyle>
                        <hr />
                        <CollectionDetailStyle>
                            <div>
                                <Label className="label-color">Assigned CS</Label>
                                <p>{data?.payment?.agent?.first_name} {data?.payment?.agent?.last_name}</p>
                            </div>
                            <div>
                                <Label className="label-color">Due Date</Label>
                                <p>{convertTimestamp(data?.due?.month_due_date)}</p>
                            </div>
                            <div>
                                <Label className="label-color">Principal Payment</Label>
                                <p>{data?.payment?.principal_received}</p>
                            </div>
                            <div>
                                <Label className="label-color">Total Principal Received</Label>
                                <p>{(+data?.payment?.principal_received + +data?.payment?.penalty_received).toFixed(2)}</p>
                            </div>
                        </CollectionDetailStyle>
                        <CollectionDetailStyle>
                            <div>
                                <Label className="label-color">Payment Reason</Label>
                                <p>{data?.payment?.payment_note}</p>
                            </div>
                            <div>
                                <Label className="label-color">PTP Date</Label>
                                <p>{data?.payment?.ptp_date ? convertTimestamp(data?.payment?.ptp_date) : '-'}</p>
                            </div>
                            <div>
                                <Label className="label-color">Reason</Label>
                                <p>{data?.payment?.remarks || '-'}</p>
                            </div>
                        </CollectionDetailStyle>
                    </CardBody>
                </Card>
            </Container>
        </div>)
}

const CollectionDetailStyle = styles.div`
    > div {
        display: inline-block;
        width: ${100 / 5}%;
        padding: 0 5px;
        vertical-align:top;
    }
    padding-top: 1.5rem;
    // border-top: 1px solid #d4d4d8;
`;

CollectionDetail.propTypes = {
    getPaymentDetailById: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    user: state.selectedById,
})

export default connect(mapStateToProps, { getPaymentDetailById })(withRouter(CollectionDetail))