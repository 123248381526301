/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect, useContext } from 'react';
import { CardBody, Card, Container, Form, Row, Col, Button, Label } from 'reactstrap';
import { Formik } from 'formik';
import { UserForm } from '../../components/tvs-components/update-forms';
import {updateUserValidate, validate } from '../../helpers/validate';
import Boundary from '../../components/layouts/Boundary';
import { toastr } from 'react-redux-toastr';
import { getCashierById } from '../../actions/fetchById';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { updateUser,clearActionStatus } from '../../actions/crudActions';
import styles from 'styled-components';
import ConfirmationModal from '../../components/tvs-components/update-forms/confirmation_modal';
import { withRouter } from 'react-router';
import { deactivateUser, reactivateUser, resetUserPhoneNumber } from '../../actions/crudActions';
import { Breadcrumb, Table } from '../../components/elements';
import { Link } from 'react-router-dom';
import { getCashierPaymentListingCols } from './paymentColumn';
import { CURRENCY_TYPE } from '../../constant/index';
import moment from 'moment';
import NepaliDate from 'nepali-date-converter';
// export const useQuery = () => {
//   return new URLSearchParams(useLocation().search);
// };

const CashierDetail = ({ 
  match, history, getCashierById, updateUser,
  deactivateUser, reactivateUser, clearActionStatus,
  resetUserPhoneNumber, user, crudState}) => {
  const formikRef = useRef(null);
  const [loading, setLoading] = useState(false)
  const [editableAddress, setEditableAddress] = useState(false);
  const [state, setState] = useState({});
  const [data, setData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [confirmChanges, setConfirmChanges] = useState(false);

  const [phoneNumberChange, setPhoneNumberChange] = useState(false)
  const [total, setTotal] = useState(0);
  const [tableState, setTableState] = useState({
    page: 1,
    sizePerPage: 20,
    month: new NepaliDate().getMonth(),
    year: new NepaliDate().getYear()
  })
  const [sort, setSort] = useState({
    s: '',
    o: ''
  })

  const toastrOptions = {
    timeOut: 6000,
    closeOnToastrClick: true,
  };


  useEffect(() => {
    getCashierById(match?.params?.id, tableState);
    setData(user.data);
  }, [match?.params?.id, tableState]);

  useEffect(()=>{
    setData(user.data)
  },[user.data])

  useEffect(() => {
    setTotal(user.data?.payments?.count)
  })

  useEffect(() => {
    if (crudState?.initiate) {
      const { pending, success, error, message } = crudState;
      setLoading(pending || false);
      if (success) {
        clearActionStatus();
        toastr.success('', message);
        history.push('/getUserList');
      } else if (error) {
        toastr.error('Input value is duplicate', error);
        clearActionStatus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crudState]);


  const submit = async (values) => {
    const existing_role = data?.user?.role;
    const email = data?.user?.email;
    const phone_number = data?.user?.phone_number;
    const country_code = data?.user?.country_code;
    const name = data?.user?.first_name+" "+data?.user?.last_name;
    const id = data?.user?.id;

    const new_email = values?.email;
    const new_phone_number = values?.phone_number;
    const new_country_code = values?.country_code;
    const city = values?.city;
    const branch = values?.branch;
    const role = values?.role;
    const remarks = values?.remarks;
    const manager_email = values?.manager;
    updateUser({id,name,email,existing_role,new_email,new_country_code,new_phone_number
    ,role,country_code,phone_number,city,branch,manager_email,remarks})
   clearActionStatus();
   setConfirmChanges(false);
    
  };

  const handleUserActivity = () => {
    const id = match?.params?.id;
    const name = data?.user?.first_name+" "+data?.user?.last_name;
    const role = data?.user?.role;
    if(data?.user?.active==='Y'){
      deactivateUser({id,name,role});
      clearActionStatus();
    } else {
      reactivateUser({id,name,role});
      clearActionStatus();
    }
  }

  const handleResetPhoneNumber = () => {
    const id = match?.params?.id;
    const email = data?.user?.email;
    resetUserPhoneNumber({id, email});
    clearActionStatus();
    history.push('/');
  }

  const onTableChange = (type, value) => {
    if (type === 'pagination') {
      setTableState({
        ...tableState,
        page: value.page
      })
    }
  }

  const resetPhoneNumberModal = <ConfirmationModal showModal={showModal} handleSubmit={handleResetPhoneNumber}
  closeModal={()=>{setShowModal(false); setConfirmChanges(false)}} 
  confirmChanges={()=>{setShowModal(false); setConfirmChanges(true);}}
  header={"Reset Phone Number?"}
  message={"Are you sure to reset phone number of the current user?"}/>

  const deactivationModal = <ConfirmationModal showModal={showModal} handleSubmit={handleUserActivity} 
  closeModal={()=>{setShowModal(false); setConfirmChanges(false)}} 
  confirmChanges={()=>{setShowModal(false); setConfirmChanges(true);}}
  header={"Confirm Status Change?"}
  message={"Are you sure to change status of the current user?"}/>
  
  return (user.data &&
    <div className="mt-0">
       <Breadcrumb
        data={[
          {
            label: 'User List',
            link: '/',
          },
          {
            label: 'Cashier Detail',
          },
        ]}
      />
      <Container style={{ paddingBottom: '100px' }}>
        <div className="pt-4 pb-2">
          <h1 className="mb-2">Cashier Detail</h1>
          <Row>
          <Col lg={10} className="d-flex justify-content-between">
          <H5>Update cashier information and contact details.<br/>Record last updated by {data?.user?.updatedBy} on {moment(data?.user?.updatedAt).format("DD MMMM YYYY")}</H5>
          <div className="d-flex justify-content-end">
            <Button className="mb-2 me-1 col-sm-4" color="primary" type="button" id="userActivationBtn" onClick={()=> {setPhoneNumberChange(false); setShowModal(true)}}>
                {data?.user?.active==='Y'? "Deactivate User" : "Activate User"}
                {!phoneNumberChange && deactivationModal}
            </Button>
            <Button className="mb-2 mx-1 col-sm-5" color="primary" type="button" id="resetPhoneNumberBtn" onClick={() => {setPhoneNumberChange(true); setShowModal(true)}}>
                Reset Phone Number
                {phoneNumberChange && resetPhoneNumberModal}
            </Button>
            <Link to={'/user-detail/change-password/'+match?.params?.id} className="mb-2 ms-1 col-sm-3">
                <Button color="primary" type="button" role='button'>
                    Reset Password
                </Button>
            </Link>
          </div>
          </Col>
          </Row>
          {data?.user?.last_login_at !== null ?
          <h4>Last Login: {moment(data?.user?.last_login_at).format("DD MMMM YYYY")}</h4>
          : <h4>Not logged in yet</h4>}
        </div>
        <>
          <Row>
            <Col lg={10}>
              <Card className="rounded-0">
                <CardBody className="p-0 rounded-0">
                  <Formik
                    innerRef={(instance) => {
                      formikRef.current = instance;
                    }}
                    initialValues={state}
                    enableReinitialize
                    onSubmit={submit}
                    validate={updateUserValidate}
                  >
                    {({ handleSubmit, errors, ...rest }) => (
                      <Form onSubmit={handleSubmit} autoComplete="off">
                        <UserForm data={data.user}/>
                      </Form>  
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
        {data?.user?.role === 'A' ?
        <div className="pt-4 pb-2">
          <h1 className="mb-2">Collection at Branch</h1>
            {data?.payments && <Label className="label-color">Last Updated at {moment(data.payments?.rows[0]?.rec_date).fromNow()}</Label>}          
            <Boundary loading={loading}>
            <>
              <div className="table-note">
                <Label className="label-color">
                  {total > tableState.sizePerPage * tableState.page ? tableState.sizePerPage * tableState.page : total} of {total} entries
                </Label>
                <Label className="label-color">* Currency in {CURRENCY_TYPE}</Label>
              </div>
              <hr></hr>
              <div id="paymentTable">
                <Table
                  data={data?.payments?.rows || []}
                  page={tableState.page}
                  columns={getCashierPaymentListingCols(sort)}
                  sizePerPage={tableState.sizePerPage}
                  totalSize={total}
                  onTableChange={onTableChange}
                  keyField="id"
                />
              </div>
            </>
          </Boundary>
        </div> : <></>}
      </Container>
      </div>
  );
};

const H5 = styles.h5`
  opacity: 0.8;
`;

CashierDetail.propTypes = {
  getCashierById : PropTypes.func.isRequired,
  user : PropTypes.object.isRequired,
  updateUser : PropTypes.func.isRequired,
  deactivateUser:PropTypes.func.isRequired,
  reactivateUser: PropTypes.func.isRequired,
  resetUserPhoneNumber: PropTypes.func.isRequired,
  clearActionStatus : PropTypes.func.isRequired,
  crudState : PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  user: state.selectedById,
  crudState : state.crudActions
})

export default connect(mapStateToProps,{getCashierById, updateUser, 
  clearActionStatus, deactivateUser, reactivateUser, resetUserPhoneNumber})(withRouter(CashierDetail));
